<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title" style="display: block;">
          <h3 class="card-label" style="display: inline-block;">
            Risk Score:
          </h3>

          <div
            class="v-avatar mr-2"
            style="height: 28px; min-width: 28px; width: 28px; background-color: #3699FF !important; border-color: #3699FF !important;"
          >
            <span
              class="white--text headline"
              v-if="assessment_list.length == 0"
            >
              <!-- {{ (Number(risk_score)).toFixed(0) }} -->
              NA
            </span>
            <span class="white--text headline" v-else>
              NA
            </span>
          </div>
          <!-- v-tooltip bottom v-if="assessment_list.length == 0">
            <template v-slot:activator="{ on }">
              <img v-on="on" src="media/svg/icons/Code/Info-circle.svg" />
            </template>
            <span>
              Score = (HR Risk Score * 0.2) <br />
              + (Operational Risk Score * 0.2) <br />
              + (Fraud Risk Score * 0.25) <br />
              + (Cyber Risk Score * 0.1) <br />
              + (Compliance Risk Score * 0.2)
            </span>
          </v-tooltip -->
          <div
            class="col-md-12 my-2 my-md-0"
            style="padding: 0;padding-top: 10px;"
          >
            <b>Compliance Risk Program Stage: </b>
            <span v-if="assessment_list.length == 0">
              <!-- Phase 2 Building (Static) -->
              NA
            </span>
            <span v-else>NA</span>
          </div>
        </div>
        <div class="card-toolbar">
          <div class="example-tools" v-if="assessment_list.length == 0 && is_action_item">
            <a @click="viewActionItems()" class="btn btn-primary font-weight-bolder mr-5">
              <span class="svg-icon svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20, 10.5522847 19.5522847,11 19,11 C18.4477153,
                        11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </svg>
              </span>
              View Action Items
            </a>
            <div class="my-2 mr-3"></div>
          </div>
          <div class="my-2" v-if="is_back">
            <button
              type="button"
              class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
            >
              <span class="v-btn__content" @click="redirectToAssessmentList()">
                Back
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";
// import axios from "axios";

export default {
  name: "AssessmentScoreCard",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      risk_score: 0
    };
  },
  props: {
    assessment_list: [],
    is_action_item: Boolean,
    is_back: Boolean
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    let context = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Action Items" }]);
    EventBus.$on("SET_ASSESSMENT_SCORE", function(payLoad) {
      context.risk_score = payLoad.toFixed(0);
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    redirectToAssessmentList() {
      let context = this;
      context.$router.go(-1);
    },
    viewActionItems() {
      this.$router.push({name: "ActionItems"});
    },
  }
};
</script>
