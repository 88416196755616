<template>
  <div>
    <assessment-score-card
      :assessment_list="assessment_list"
      :is_action_item="true"
      :is_back="false"  
    >
    </assessment-score-card>
    <!--begin::Assessments-->
    <div class="card card-custom gutter-b" v-if="fill_assessment_section">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Available Risk Assessments</h3>
        </div>
      </div>
      <div class="card-toolbar"></div>
      <div class="card-body">
        <form class="form" novalidate="novalidate">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <template v-for="assessment in assessment_list">
                  <div
                    :key="assessment.id"
                    v-if="assessment.name == 'Human Resources Risk Assessment'"
                    style="cursor:pointer;background-color: rgb(56, 95, 115); border-color: rgb(56, 95, 115);text-align: center; width: 22%; "
                    class="v-card v-sheet theme--dark mr-2"
                  >
                    <div
                      class="v-card__title headline"
                      @click="startAssessment(assessment.id , 0)"
                    >
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        {{ assessment.name.split("Assessment")[0] }}
                      </p>
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        Assessment
                      </p>
                    </div>
                  </div>
                  <div
                    :key="assessment.id"
                    v-if="assessment.name == 'Occupational Fraud Risk Assessment'"
                    style="cursor:pointer;background-color: rgb(31, 112, 135); border-color: rgb(31, 112, 135);text-align: center; width: 22%; "
                    class="v-card v-sheet theme--dark mr-2"
                  >
                    <div
                      class="v-card__title headline"
                      @click="startAssessment(assessment.id, 0)"
                    >
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        {{ assessment.name.split("Assessment")[0] }}
                      </p>
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        Assessment
                      </p>
                    </div>
                  </div>
                  <div
                    :key="assessment.id"
                    v-if="assessment.name == 'Workplace Violence Risk Assessment'"
                    style="cursor:pointer;background-color: rgb(149, 33, 117); border-color: rgb(149, 33, 117);text-align: center;  width: 22%; "
                    class="v-card v-sheet theme--dark mr-2"
                  >
                    <div
                      class="v-card__title headline"
                      @click="startAssessment(assessment.id, 0)"
                    >
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        {{ assessment.name.split("Assessment")[0] }}
                      </p>
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        Assessment
                      </p>
                    </div>
                  </div>
                  <div
                    :key="assessment.id"
                    v-if="assessment.name == 'Cybersecurity Risk Assessment'"
                    style="cursor:pointer;background-color: rgb(38, 198, 218); border-color: rgb(38, 198, 218);text-align: center;  width: 22%; "
                    class="v-card v-sheet theme--dark mr-2"
                  >
                    <div
                      class="v-card__title headline"
                      @click="startAssessment(assessment.id, 0)"
                    >
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        {{ assessment.name.split("Assessment")[0] }}
                      </p>
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        Assessment
                      </p>
                    </div>
                  </div>
                  <div
                    :key="assessment.id"
                    v-if="assessment.name == 'Compliance Risk Assessment'"
                    style="cursor:pointer;background-color: #3699ff; border-color: #3699ff;text-align: center; width: 22%; "
                    class="v-card v-sheet theme--dark mr-2"
                  >
                    <div
                      class="v-card__title headline"
                      @click="startAssessment(assessment.id, 0)"
                    >
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        {{ assessment.name.split("Assessment")[0] }}
                      </p>
                      <p style="width: 100%; margin: 0; line-height: 25px;">
                        Assessment
                      </p>
                    </div>
                  </div>
                </template>

                <!-- <div
                  v-for="assessment in assessment_list"
                  :key="assessment.id"
                  class="col-md-4 my-2 my-md-0"
                >
                  <div class="my-2">
                    <v-btn
                      @click="startAssessment(assessment.id)"
                      x-large
                      color="success"
                      dark
                    >
                      {{ assessment.name }}
                    </v-btn>
                  </div>
                </div> -->
              </v-card-title>
            </v-card>
          </div>
        </form>
      </div>
    </div>

    <div
      class="card card-custom gutter-b"
      v-if="incomplete_assessment_list.length > 0"
    >
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Incompleted Assessments</h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-data-table
                :headers="incomplete_headers"
                :items="incomplete_assessment_list"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.assessment_name }}</td>
                    <td>{{ row.item.no_of_question }}</td>
                    <td>{{ row.item.no_of_answered_question }}</td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="restartAssessment(row.item)"
                          >
                            <v-icon color="#B5B5C3">mdi-restart</v-icon>
                          </v-btn>
                        </template>
                        <span>Restart Assessment</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>

    <div class="card card-custom gutter-b" v-if="user_permissions.includes('se_view_risk_assessment_result')">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Completed Assessment</h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-data-table
                :headers="headers"
                :items="completed_assessment_list"
              >
                <template v-slot:item="row">

                  <div id="printDiv" style="display:none;">
                    <div>
                      <h3>Assessment Result</h3>
                      <div class="row m-0" style="align-items: baseline;">
                      <div class="pr-10" style="display: inline-block;">
                        <p class="text-h6 pa-5" style="margin: 0px;">
                          <b>Assessment Name: </b>{{ dialogue_assessment_name }}<br />
                          <!-- <b>Assessment Score: </b>{{ dialogue_assessment_score }}<br />
                          -->
                          <b>Assessment Risk: </b>
                          <span v-if="dialogue_risk_lable == 'Low Risk'" class="green-font">
                            {{ dialogue_risk_lable }}
                          </span>
                          <span v-if="dialogue_risk_lable == 'Moderate Risk'" class="yellow-font">
                            {{ dialogue_risk_lable }}
                          </span>
                          <span v-if="dialogue_risk_lable == 'High Risk'" class="red-font">
                            {{ dialogue_risk_lable }}
                          </span>
                        </p>
                      </div>
                      <div style="display: inline-block; margin-left: 40px;">   
                          <img
                            v-if="dialogue_risk_lable == 'Low Risk'" class="green-font"
                            src="media/assessment-img/low.png"
                            alt="Assessment Risk"
                            style="height: 70px;"
                          />
                          <img
                            v-if="dialogue_risk_lable == 'Moderate Risk'" class="green-font"
                            src="media/assessment-img/medium.png"
                            alt="Assessment Risk"
                            style="height: 70px;"
                          />
                          <img
                            v-if="dialogue_risk_lable == 'High Risk'" class="green-font"
                            src="media/assessment-img/high.png"
                            alt="Assessment Risk"
                            style="height: 70px;"
                          />
                      </div>  
                      </div>  
                        <p class="text-h6 pa-5" style="margin-top: 5px;">
                          <b>Risk Interpretation: </b>{{ dialogue_risk_description }}
                        </p>
                    </div>
                    <div>
                      <h3>Assessment Feedback & Recommended Training</h3>
                      <h4>Assessment Feedback</h4>
                      <br>
                      <ul>
                        <template v-for="(feedback, index) in feedback_points">
                          <span v-if="feedback" :key='"feed"+index'>
                            <li><div style="font-size: 18px;" v-html="feedback"></div></li>
                          </span>
                          <br v-if="feedback" :key='"feed_"+index'/>
                        </template>
                      </ul>
                      <h4>Recommended Training</h4>
                      <br>
                      <ul>
                        <template v-for="(training, index) in rec_training">
                          <span v-if="training" :key='"tr"+index'>
                            <li><div style="font-size: 18px;" v-html="training"></div></li>
                          </span>
                          <br v-if="training" :key='"tr_"+index'/>
                        </template>
                      </ul>
                    </div>
                    <!--div>
                      <h3>Assessment Recommendation</h3>
                        <p class="text-h6 pa-5">
                          <template v-for="(recommandation, index) in recommandations">
                            <span v-if="recommandation" :key='"rec"+index'>
                              <div style="font-size: 18px;" v-html="recommandation"></div>
                            </span>
                          </template>
                        </p>
                    </div-->
                  </div>

                  <tr>
                    <td>{{ row.item.assesment_name }}</td>
                    <td>{{ row.item.date.slice(0, 10) }}</td>
                    <td>
                    <span v-if="row.item.interpretation_label == 'Low Risk'" class="green-font">
                      {{ row.item.interpretation_label }}
                    </span>
                    <span v-if="row.item.interpretation_label == 'Moderate Risk'" class="yellow-font">
                      {{ row.item.interpretation_label }}
                    </span>
                    <span v-if="row.item.interpretation_label == 'High Risk'" class="red-font">
                      {{ row.item.interpretation_label }}
                    </span>
                    </td>
                    <td class="text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2 v-btn--round v-btn--fab v-size--small"
                            small
                            v-on="on"
                            @click="showAssessmentResult(row.item)"
                          >
                            <v-icon color="#B5B5C3">mdi-file-document</v-icon>
                          </v-btn>
                        </template>
                        <span>Show Result</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2 v-btn--round v-btn--fab v-size--small"
                            small
                            v-on="on"
                            v-bind="attrs"
                            @click="showFeedback(row.item)"
                          >
                            <v-icon color="#B5B5C3">mdi-message-alert</v-icon>
                          </v-btn>
                        </template>
                        <span>Show Feedback</span>
                      </v-tooltip>
                      <!--v-tooltip top>

                        <template v-slot:activator="{ on }" >
                          <v-btn
                            class="mx-2 v-btn--round v-btn--fab v-size--small"
                            small
                            v-on="on"
                            @click="showRecommendation(row.item)"
                          >
                            <v-icon color="#B5B5C3">mdi-ballot-recount</v-icon>
                          </v-btn>
                        </template>
                        <span>Show Recommendation</span>
                      </v-tooltip-->
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2 v-btn--round v-btn--fab v-size--small"
                            small
                            v-on="on"
                            @click="startAssessment(row.item.assessment_master_id , 1)"
                          >
                            <v-icon color="#B5B5C3">mdi-reload</v-icon>
                          </v-btn>
                        </template>
                        <span>Reattempt Assessment</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2 v-btn--round v-btn--fab v-size--small"
                            small
                            v-on="on"
                            @click="setValues(row.item)"
                          >
                            <v-icon color="#B5B5C3">mdi-printer</v-icon>
                          </v-btn>
                        </template>
                        <span>Print</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Assessments-->

    <!-- Assessment Dialogue -->
    <div class="text-center">
      <v-dialog v-model="assessment_dialog" width="70%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Assessment Result
          </v-card-title>

          <v-card-text class="text-h6 pa-5">
          <div class="row m-0" style="align-items: baseline;">
            <div class="pr-10">
            <b>Assessment Name: </b>{{ dialogue_assessment_name }}<br />
            <!--
            <b>Assessment Score: </b>{{ dialogue_assessment_score }}<br />
            -->
            <b>Assessment Risk: </b>
            <span v-if="dialogue_risk_lable == 'Low Risk'" class="green-font">
              {{ dialogue_risk_lable }}
            </span>
            <span v-if="dialogue_risk_lable == 'Moderate Risk'" class="yellow-font">
              {{ dialogue_risk_lable }}
            </span>
            <span v-if="dialogue_risk_lable == 'High Risk'" class="red-font">
              {{ dialogue_risk_lable }}
            </span>
            </div>

            <div>
            <img
              v-if="dialogue_risk_lable == 'Low Risk'" class="green-font"
              src="media/assessment-img/low.png"
              alt="Assessment Risk"
              style="height: 70px;"
            />
            <img
              v-if="dialogue_risk_lable == 'Moderate Risk'" class="green-font"
              src="media/assessment-img/medium.png"
              alt="Assessment Risk"
              style="height: 70px;"
            />
            <img
              v-if="dialogue_risk_lable == 'High Risk'" class="green-font"
              src="media/assessment-img/high.png"
              alt="Assessment Risk"
              style="height: 70px;"
            />
            </div>
          </div>

          <b>Risk Interpretation: </b>{{ dialogue_risk_description }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="assessment_dialog = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Assessment Dialogue -->

    <!-- Feedback Dialogue -->
    <div class="text-center">
      <v-dialog v-model="feedback_dialogue" width="70%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Assessment Feedback & Recommended Training
          </v-card-title>

          <v-card-text class="text-h6 pa-5">
            <div class="row m-0" style="align-items: baseline;">
            <div class="pr-10" style="font-size: 20px; color:#9500b8;"><b>Assessment Feedback</b></div>
            <div>
            <img
                v-if="dialogue_risk_lable == 'Low Risk'" class="green-font"
                src="media/assessment-img/low.png"
                alt="Assessment Risk"
                style="height: 70px;"
              />
              <img
                v-if="dialogue_risk_lable == 'Moderate Risk'" class="green-font"
                src="media/assessment-img/medium.png"
                alt="Assessment Risk"
                style="height: 70px;"
              />
              <img
                v-if="dialogue_risk_lable == 'High Risk'" class="green-font"
                src="media/assessment-img/high.png"
                alt="Assessment Risk"
                style="height: 70px;"
              />
            </div>
            </div>
            <ul>
              <template v-for="(feedback, index) in feedback_points">
                <span v-if="feedback" :key="index">
                  <li><div style="font-size: 18px;" v-html="feedback"></div></li>
                </span>
                <br v-if="feedback" :key="index" />
              </template>
            </ul>
          </v-card-text>

          <v-card-text class="text-h6 pa-5">
          <div style="font-size: 20px; color:#9500b8;"><b>Recommended Training</b></div>
            <br/>
            <ul>
              <template v-for="(training, index) in rec_training">
                <span v-if="training" :key="index">
                  <li><div style="font-size: 18px;" v-html="training"></div></li>
                </span>
                <br v-if="training" :key="index" />
              </template>
            </ul>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="feedback_dialogue = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Feedback Dialogue -->
    <!-- Recommendation Dialogue -->
    <div class="text-center">
      <v-dialog v-model="recommendation_dialogue" width="70%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Assessment Recommendation
          </v-card-title>

          <v-card-text class="text-h6 pa-5">
            <template v-for="(recommandation, index) in recommandations">
              <span v-if="recommandation" :key="index">
                <div style="font-size: 18px;" v-html="recommandation"></div>
              </span>
            </template>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="recommendation_dialogue = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Recommendation Dialogue -->
  </div>
</template>

<style lang="scss" scoped>
.v-card__title {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  word-break:unset !important;
}
.v-application .headline {
  height: 100%;
}
</style>

<script>
import $ from 'jquery';
import Vue from 'vue';
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import axios from "axios";
// import EventBus from "@/core/services/store/event-bus";
import AssessmentScoreCard from "./AssessmentScoreCard.vue";
import { GET_ASSESSMENT_SCORE } from "@/core/services/store/assessment.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
    autoClose: true, // if false, the window will not close after printing
}
Vue.use(VueHtmlToPaper,options);

export default {
  name: "AssessmentList",
  vuetify: new Vuetify(),
  components: {
    "assessment-score-card": AssessmentScoreCard,
  },
  data() {
    return {
      user_permissions: [],
      assessment_list: {},
      completed_assessment_list: [],
      fill_assessment_section: true,
      incomplete_assessment_list: [],
      incomplete_headers: [
        {
          text: "Assessment",
          align: "left",
          value: "assessment_name",
          width: "25%"
        },
        { text: "Total Questions", value: "no_of_question", width: "25%" },
        {
          text: "Total Answered",
          value: "no_of_answered_question",
          width: "25%"
        },
        { text: "Action", value: "action", width: "25%" }
      ],
      headers: [
        {
          text: "Assessment",
          align: "left",
          value: "assessment",
          width: "40%"
        },
        { text: "Date", value: "date", width: "20%" },
        { text: "Assessment Risk", value: "interpretation_label", width: "10%" },
        { text: "Result", value: "result", width: "30%", align: "center" }
      ],
      dialogue_risk_description: "",
      dialogue_risk_lable: "",
      dialogue_assessment_name: "",
      dialogue_assessment_score: "",
      assessment_dialog: false,
      feedback_dialogue: false,
      recommendation_dialogue: false,
      feedback_points: [],
      recommandations: [],
      rec_training: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getAssessmentsList", "getCompletedAssessments", "getUserPermissions"]),
    config() {
      return this.layoutConfig();
    }
  },
  watch: {
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  mounted() {
    // set the tab from previous
    // let context = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Risk Assessment" }]);
    this.getAssessmentList();
    this.getCompletedAssessmentList();
    this.getIncompleteAssessmentList();
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    setValues(assessment){
      let context = this;
      context.dialogue_risk_description = assessment.interpretation_text;
      context.dialogue_risk_lable = assessment.interpretation_label;
      context.dialogue_assessment_name = assessment.assesment_name;
      context.dialogue_assessment_score = assessment.final_score;
      context.feedback_points = assessment.feedback_points;
      context.rec_training = assessment.rec_training;
      context.recommandations = assessment.recommandations;
      setTimeout(() => {
        context.myPrint();
      }, 2000);
    },
    myPrint() {
      let context = this;
      context.$htmlToPaper('printDiv');
    },
    showRecommendation(assessment) {
      let context = this;
      context.recommandations = assessment.recommandations;
      context.recommendation_dialogue = true;
    },
    showFeedback(assessment) {
      let context = this;
      context.feedback_points = assessment.feedback_points;
      context.rec_training = assessment.rec_training;
      context.dialogue_risk_lable = assessment.interpretation_label;
      context.feedback_dialogue = true;
    },
    showAssessmentResult(assessment) {
      let context = this;
      context.dialogue_risk_description = assessment.interpretation_text;
      context.dialogue_risk_lable = assessment.interpretation_label;
      context.dialogue_assessment_name = assessment.assesment_name;
      context.dialogue_assessment_score = assessment.final_score;
      context.assessment_dialog = true;
    },
    restartAssessment(assessment) {
      this.$router.push({
        name: "assessment.restart",
        params: {
          id: assessment.assessment_master_id,
          attempt: assessment.attempt,
          question_id: assessment.next_question_id
        }
      });
    },
    getIncompleteAssessmentList() {
      let context = this;
      axios({
        method: "get",
        url: "incomplete-assessment",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.result) {
            context.incomplete_assessment_list = result.data.result;
          }
        },
        function() {
          Swal.fire("Error", "Incomplete Assessment fetch error!", "error");
        }
      );
    },
    getCompletedAssessmentList() {
      let context = this;
      // this.$store.dispatch(GET_COMPLETED_ASSESSMENTS).then(() => {
      //   context.completed_assessment_list = context.getCompletedAssessments;
      //   alert(JSON.stringify(context.completed_assessment_list));
      // });
      axios({
        method: "get",
        url: "complete-assessments",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.completed_assessment_list = result.data.result;
          // context.calculateRiskScore(context.completed_assessment_list);
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching completed assessment list",
            "info"
          );
        }
      );
    },
    // calculateRiskScore(assessment_list) {
    //   let total_score = 0;
    //   let context = this;
    //   assessment_list.forEach(assessment => {
    //     if (
    //       assessment.assesment_name.includes("hr") ||
    //       assessment.assesment_name.includes("HR")
    //     ) {
    //       total_score += parseFloat(0.2 * parseInt(assessment.section_score));
    //     } else if (
    //       assessment.assesment_name.includes("Fraud") ||
    //       assessment.assesment_name.includes("fraud")
    //     ) {
    //       total_score += parseFloat(0.25 * parseInt(assessment.section_score));
    //     } else if (
    //       assessment.assesment_name.includes("Cyber") ||
    //       assessment.assesment_name.includes("cyber")
    //     ) {
    //       total_score += parseFloat(0.1 * parseInt(assessment.section_score));
    //     } else if (
    //       assessment.assesment_name.includes("Compliance") ||
    //       assessment.assesment_name.includes("compliance")
    //     ) {
    //       total_score += parseFloat(0.2 * parseInt(assessment.section_score));
    //     } else if (
    //       assessment.assesment_name.includes("Operational") ||
    //       assessment.assesment_name.includes("operational")
    //     ) {
    //       total_score += parseFloat(0.2 * parseInt(assessment.section_score));
    //     }
    //   });
    //   context.risk_score = total_score;
    // },
    startAssessment(assessment_id, attempt) {
      this.$router.push({
        name: "assessment.fill",
        params: { id: assessment_id, attempt: attempt }
      });
    },
    getAssessmentList() {
      let context = this;
      axios({
        method: "get",
        url: "assessments",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.assessment_list = result.data.assessments;
          if (context.assessment_list.length == 0) {
            context.fill_assessment_section = false;
            context.$store.dispatch(GET_ASSESSMENT_SCORE);
          }
        },
        function() {
          Swal.fire("Error", "Error in fetching Assessment list", "info");
        }
      );
    }
  }
};
</script>
